import type {
  RouteLocationNormalized,
} from 'vue-router';

import {
  assert,
  isOptionalString,
  isUndefined,
} from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';
import { AbstractPrefetch } from '@core/router';

import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
import { useRegistrationCompletionStore } from 'web/src/modules/registration-completion/store';

export default class RegistrationCompletionPrefetch extends AbstractPrefetch {
  async prefetch(
    _: AppVueRouter,
    to: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    if (
      (to.params.customerAction === CustomerCompletionAction.Withdrawal
        || to.params.customerAction === CustomerCompletionAction.Deposit
      )
      && isUndefined(to.params.paymentSystemId)
    ) {
      return {
        name: RouteName.HOME,
      };
    }

    const { customerAction, paymentSystemId } = to.params;
    assert(isOptionalString(customerAction));
    assert(isOptionalString(paymentSystemId));

    await useRegistrationCompletionStore().fetchIncompleteRegistrationFields({
      customerAction,
      paymentSystemId,
    });
  }
}
