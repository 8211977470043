import type { Ref } from 'vue';
import {
  computed,
} from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useI18n } from '@core/i18n';

import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';

export interface RegistrationCompletionTopBarRouteComponentComposable {
  topBarTitle: Ref<string>;
  isAsoTerms: Ref<boolean>;
  showPrefix: Ref<boolean>;
  onClose(): void;
  onBack(): void;
}

export function useRegistrationCompletionTopBarRouteComponent(): RegistrationCompletionTopBarRouteComponentComposable {
  const { $translate } = useI18n();
  const router = useRouter();
  const customerAction = computed<CustomerCompletionAction | undefined>(() => router.getParam<CustomerCompletionAction>('customerAction'));

  const topBarTitle = computed(() => (
    customerAction.value === CustomerCompletionAction.ForbiddenCountry
      ? $translate('WEB2_REGISTRATION_COMPLETION_COUNTRY_TITLE').value
      : $translate('WEB2_REGISTRATION_COMPLETION_COMMON_TITLE').value
  ));
  const isAsoTerms = computed(() => customerAction.value === CustomerCompletionAction.AsoTerms);
  const showPrefix = computed(() => {
    switch (customerAction.value) {
      case CustomerCompletionAction.AsoTerms:
        return false;
      case CustomerCompletionAction.ForbiddenCountry:
        return !process.env.VUE_APP_LAYOUT_DESKTOP;
      default:
        return true;
    }
  });

  function onClose(): void {
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.back();
      return;
    }
    void router.closeModal();
  }

  function onBack(): void {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      if (customerAction.value === CustomerCompletionAction.Deposit) {
        void router.push({ name: RouteName.DEPOSITS });
      }
      if (customerAction.value === CustomerCompletionAction.Withdrawal) {
        void router.push({ name: RouteName.WITHDRAWALS });
      }
    } else {
      router.back();
    }
  }

  return {
    topBarTitle,
    isAsoTerms,
    showPrefix,
    onClose,
    onBack,
  };
}
